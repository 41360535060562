import { UploadForm } from "./index.js";
import goals from '../utils/goals';

export class UploadPromoForm extends UploadForm {
    maxFiles = 1;
    isPromoForm = true;

    onAddFile (response) {
        goals.reach('startFileUploadPromo');
    }
}
